import { default as React, useContext } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

import { IInterval } from '@mylight/data-model';

import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import Spinner from '../../../ui/Spinner/Spinner';
import { ApolloError } from 'apollo-client';
import {
  CustomField,
  Field,
  FieldList
} from '../../../ui/DataDisplay/FieldList/FieldList';
import { wattPeakFormatter } from '../../../utils/tools';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import BatteryGetInfoQuery from './battery/info/BatteryGetInfoQuery';
import { InstallationContext } from './Installation.context';
import { MasterConfigFieldsQuery } from './masterConfig/MasterConfigFieldsQuery';
import MsbActivationView from './battery/activation/MsbActivationView';
import SidebarGuide from './../../navigation/SidebarGuide/SidebarGuide';
import { installationTabSidebarGuideLinks } from './../../navigation/SidebarGuide/SidebarGuideLinks';

interface IProps {
  customerId: string;
  loading: boolean;
  error?: ApolloError;
  refetch?: any;
  t: TranslationFunction;
  goToEdit: () => void;
}

const TimeSlots = translate('Common')(
  ({
    timeSlots,
    t
  }: {
    timeSlots: IInterval[] | undefined;
    t: TranslationFunction;
  }) => {
    return (
      <div style={{ paddingRight: 0, paddingLeft: 0 }}>
        {timeSlots &&
          timeSlots.map((s, i) => (
            <div
              key={i}
              style={{
                paddingRight: 0,
                paddingLeft: 0,
                marginTop: i > 0 ? '10px' : '0px'
              }}
            >
              {t('from')} <span className="field text">{s.start}</span>{' '}
              {t('to')} <span className="field text">{s.end}</span>
            </div>
          ))}
      </div>
    );
  }
);

const InstallationView = ({
  customerId,
  loading,
  error,
  refetch,
  t,
  goToEdit
}: IProps) => {
  const { installation } = useContext(InstallationContext);

  const timeSlots =
    installation &&
    installation.powerContractType &&
    installation.powerContractType.timeSlots &&
    installation.powerContractType.timeSlots.length > 0
      ? installation.powerContractType.timeSlots
      : undefined;

  return (
    <BatteryGetInfoQuery>
      <Spinner loading={loading} error={error ? error.message : undefined}>
        <Container fluid>
          <Col className="mobile-helper">
            <Row>
              <Col md={12} lg={6}>
                <FieldList>
                  <ListTitle
                    text={t('installation')}
                    children={
                      <Button
                        disabled={!(installation && installation.canWrite)}
                        onClick={() => goToEdit()}
                        className="btn-ico"
                      >
                        <i className="icon-profile" />
                      </Button>
                    }
                  />
                  <Field
                    name={t('Installation:pdl')}
                    text={installation && installation.pdlNumber}
                  />
                  <Field
                    name={t('converterType')}
                    text={installation && t(installation.converterType)}
                  />
                  <Field
                    name={t('panelCount')}
                    text={
                      installation &&
                      installation.panelCount &&
                      installation.panelCount.toString()
                    }
                  />
                  <Field
                    name={t('wattPeakByPanel')}
                    text={
                      installation &&
                      wattPeakFormatter(installation.wattCreteByPanel)
                    }
                  />
                  <Field
                    name={t('panelTotalPower')}
                    text={
                      installation && wattPeakFormatter(installation.totalPower)
                    }
                  />
                  <Field
                    name={t('panelManufacturer')}
                    text={installation && installation.panelProvider}
                  />

                  <Field
                    name={t('saleOfSurplus')}
                    /* FIXME : use formatter */
                    text={
                      installation && installation.saleOfSurplus
                        ? `${installation.saleOfSurplus} c${
                            installation.currency === 'EUR'
                              ? '€'
                              : installation.currency
                          }/kWh`
                        : undefined
                    }
                  />
                  <Field
                    name={t('electricityProvider')}
                    text={
                      installation &&
                      installation.powerContractType.powerProvider
                    }
                  />
                  <Field
                    name={t('powerContractType')}
                    text={
                      installation &&
                      t(
                        `PowerContractType:${installation.powerContractType
                          .name || ''}`
                      )
                    }
                  />
                  {timeSlots &&
                    timeSlots.length > 0 && (
                      <CustomField name={t('timeSlots')}>
                        <TimeSlots timeSlots={timeSlots} />
                      </CustomField>
                    )}

                  <Field
                    name={t('rackingPower')}
                    text={
                      installation && installation.rackingPower
                        ? `${installation.rackingPower} kVa`
                        : ''
                    }
                  />
                  <Field
                    name={t('gridElectricalPhaseType')}
                    text={installation && t(installation.gridType)}
                  />
                  <Field
                    name={t('internetServiceProvider')}
                    text={installation && installation.internetServiceProvider}
                  />
                  <Field
                    name={t('internetBoxModel')}
                    text={installation && installation.internetBoxModel}
                  />
                  {installation && (
                    <MasterConfigFieldsQuery customerId={customerId} />
                  )}
                </FieldList>
              </Col>
              <Col md={12} lg={6} className={'my-smart-battery'}>
                <MsbActivationView refetchInstallationQuery={refetch} />
              </Col>
            </Row>
          </Col>
        </Container>
      </Spinner>
      <SidebarGuide items={ installationTabSidebarGuideLinks } />
    </BatteryGetInfoQuery>
  );
};

export default translate([
  'Customer',
  'ContractName',
  'Installation',
  'Devices'
])(InstallationView);
