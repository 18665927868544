import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import classnames from 'classnames';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ShowAlert from './../../../../../../ui/ShowAlert/ShowAlert';

export interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  isModalOpen?: boolean;
  toggle: any;
  message: string;
}

const EnedisConnectionPowerInjectionErrorModal = ({
  t,
  isModalOpen,
  toggle,
  message,
}: IProps) => {
  return (
    <Modal
      centered
      isOpen={ isModalOpen }
      className={ 'enedis-connection-power-error-modal' }
      size='md'
    >
      <ModalHeader toggle={ toggle }>
        { t('Installation:invalidConnectionPowerInjectionPowerHeader') }
      </ModalHeader>

      <ModalBody className={ classnames('p-4') }>
        <ShowAlert 
          type={ 'danger' } 
          message={ message } 
          margin={ 0 }
          padding={ 0 } />
      </ModalBody>
    </Modal>
  )
};

export default withRouter(
  translate(['Installation, Common'])(EnedisConnectionPowerInjectionErrorModal)
);