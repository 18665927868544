import { GridType } from '@mylight/data-model';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';

export default {
  MonthNames: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  },
  InscriptionAlerts: {
    modalTitle: 'Attention !',
    ALERT_GRID_TYPE_DIFFERENT_UCG_MODEL:
      "L'UCG renseigné ne correspond pas au type de réseau sélectionné",
    ALERT_NUMBER_GMD_HIGHER_UCG_MODEL: "Trop de GMD pour l'UCG renseigné",
    ALERT_NUMBER_GMD_LOWER_UCG_MODEL: "Pas assez de GMD pour l'UCG renseigné",
    ALERT_NUMBER_TPH_HIGHER_UCG_MODEL: "Trop de TPH pour l'UCG renseigné",
    ALERT_NUMBER_TPH_LOWER_UCG_MODEL: "Pas assez de TPH pour l'UCG renseigné"
  },
  RegistrationStatus: {
    [RegistrationStatus.SETUP_OK]: 'Compte client non actif',
    [RegistrationStatus.USER_SETTINGS_SET]: 'Compte client non actif',
    [RegistrationStatus.USER_PASSWORD_SET]: 'Compte client non actif',
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: 'Compte client non actif',
    [RegistrationStatus.OK]: 'Terminé',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]: 'En cours',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]: 'En cours',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]: 'En cours',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]: 'En cours',
    [RegistrationStatus.SETUP_CONNECT_TADO]: 'En cours',
    [RegistrationStatus.SETUP_CHECK_WIRING]: 'En cours',
    [RegistrationStatus.SETUP_USER_REGISTERED]: 'En cours'
  },
  RegistrationStatusToolTips: {
    [RegistrationStatus.SETUP_OK]: `Inscription matérielle terminée.<br />Le client doit valider son compte.<br />Vous pouvez lui renvoyer l'email d'activation via la page <a href="{{link}}">Système MyLight</a> du client`,
    [RegistrationStatus.USER_SETTINGS_SET]: `Inscription matérielle terminée.<br />Le client doit valider son compte.<br />Vous pouvez lui renvoyer l'email d'activation via la page <a href="{{link}}">Système MyLight</a> du client`,
    [RegistrationStatus.USER_PASSWORD_SET]: `Inscription matérielle terminée.<br />Le client doit valider son compte.<br />Vous pouvez lui renvoyer l'email d'activation via la page <a href="{{link}}">Système MyLight</a> du client`,
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: `Inscription matérielle terminée.<br />Le client doit valider son compte.<br />Vous pouvez lui renvoyer l'email d'activation via la page <a href="{{link}}">Système MyLight</a> du client`,
    [RegistrationStatus.OK]: 'Le client a accès à son application',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_CONNECT_TADO]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_CHECK_WIRING]:
      'Inscription matérielle non terminée',
    [RegistrationStatus.SETUP_USER_REGISTERED]:
      'Inscription matérielle non terminée'
  },
  Customer: {
    [GridType.SINGLE_PHASE]: 'Monophasé',
    [GridType.THREE_PHASE]: 'Triphasé',
    accountCreationDate: 'Date de création du compte',
    restrictedAccess: `Le client n'autorise pas l'accès à ses données d'installation et ses données de consommation/production.`,
    addACustomer: 'Ajouter un client',
    address: 'Adresse',
    birthDate: 'Date de naissance',
    city: 'Ville',
    companyName: 'Entreprise',
    converterType: 'Type d’onduleur',
    customers: 'Clients',
    device: 'Appareil',
    deviceMac: 'MAC',
    controlledDeviceName: 'Nom',
    devicePower: 'Puissance',
    devicesList: 'Liste des appareils',
    deviceStatus: 'État',
    deviceType: 'Type',
    deviceTypeName: "Type d'appareils",
    disabled: 'Désactivé',
    electricityContract: "Contrat d'électricité",
    electricityProvider: "Fournisseur d'électricité",
    email: 'Email',
    emailAlert: "Envoi des alertes par e-mail à l'adresse renseignée ?",
    enabled: 'Activé',
    fillMissingFields: `Pour créer un utilisateur veuillez remplir les champs suivants:`,
    globalConsumption: 'Consommation totale',
    gridElectricalPhaseType: "Type d'installation",
    internetProvider: 'Fournisseur internet',
    internetBoxModel: 'Type de box internet',
    internetServiceProvider: "Fournisseur d'accès internet",
    installation: 'Installation',
    installer: 'Installateur',
    installerLoadingError: 'Erreur de chargement des installateurs',
    lastConnection: 'Dernière connection',
    ignored: 'Ignoré',
    lastConnections: 'Dernière(s) connection(s)',
    lastRegisteredCustomers: 'Derniers clients inscrits',
    firstName: 'Prénom',
    licence: 'Licence',
    loginAs: 'Interface client',
    maintainer: 'Vendeur',
    map: 'Carte',
    masterActivationSerialNumber: "Numéro d'activation de la Master",
    mobile: 'Mobile',
    name: 'Nom',
    gridConsumption: 'Consommation réseau',
    no: 'Non',
    panelCount: 'Nombre de module',
    panelInstallation: 'Installation photovoltaïque',
    panelTotalPower: 'Puissance totale installée',
    panelManufacturer: 'Fabricant des panneaux',
    performanceRecords: 'Relevés de performance',
    phone: 'Telephone',
    powerContractType: "Type de contrat d'électricité",
    powerContractOption: "Contrat d'électricité autre",
    postalCode: 'Code postal',
    production: 'Production',
    rackingPower: 'Puissance de soutirage',
    registrationDate: 'Date de mise en service',
    registrationStatus: "État d'inscription",
    resendActivationEmail: 'Envoyer',
    resendActivationEmailSuccess: "L'email d'activation a bien été renvoyé.",
    resendActivationEmailFailure: "L'email d'activation n'a pas pu être envoyé",
    resendActivationEmailAlreadyActivated: 'Cet utilisateur est déjà actif.',
    saleOfSurplus: 'Vente surplus électricité',
    sellingEnergyPrice: 'Prix de vente',
    smsAlert: 'Recevoir les alertes par SMS',
    street: 'Rue',
    tenant: 'Tenant',
    chooseTenant: 'Choisissez le Tenant',
    timeSlots: 'Plages horaires',
    wattPeakByPanel: 'Puissance unitaire des modules',
    yes: 'Oui',
    alerts: 'Alerts',
    newSubscription: 'NOUVEAU CLIENT',
    inscription: 'INSCRIPTION',
    inscriptionFull: 'INSCRIPTION MATÉRIELLE',
    continueInscription: `CONTINUER L'INSCRIPTION`,
    pendingCustomerEmptyList: 'Aucun client en attente',
    LastRegisteredCustomerEmptyList: 'Aucun dernier client',
    boxSerialNumber: 'Numéro de serie du coffret',
    productionAndConsumption: 'Production et consommation instantanées',
    on: 'CONNECTÉ',
    offline: 'DÉCONNECTÉ',
    off: 'ÉTEINT',
    devices: 'Liste des appareils',
    system: 'Système',
    customerEmptyList: 'Aucun client',
    emptyDeviceList: 'Aucun appareil',
    account: 'Compte',
    customer: 'Client',
    services: 'Services',
    contact: 'Contact',
    identity: 'Identité',
    country: 'Pays',
    OTHER: 'Autre',
    ENPHASE: 'Enphase',
    pendingCustomers: "Clients en cours d'installation",
    pendingActivationCustomers: "Compte en attente d'activation",
    resendActivationEmailLabel: "Renvoyer le mail d'activation",
    msbStatus_no_msb: 'Non',
    msbStatus_pending: 'En cours',
    msbStatus_finished: 'Active',
    searchAddress: "Recherche de l'adresse",
    longitude: 'Longitude',
    latitude: 'Latitude',
    canMoveCursorPositionInstallation:
      "Vous pouvez affiner la position de l'installation en déplaçant le curseur sur la carte",
    newAddressSearch: "Nouvelle recherche d'adresse",
    goToCustomer: 'Accéder au client',
    showAllCustomers: `Afficher tous les clients`
  },
  ContractName: {
    flat: 'Basique',
    custom: 'Heure pleine/creuse'
  },
  Login: {
    connection: 'Se connecter',
    email: 'Email',
    error: 'Email ou mot de passe invalide',
    password: 'Mot de passe',
    wrongPassword: 'Le champ mot de passe ne peux pas être vide',
    accountCreationLink: 'Demande de création de compte'
  },
  SubMenu: {
    identity: 'Identité',
    contactPro: 'Contacts pros',
    installation: 'Installation',
    system: 'Système Mylight',
    support: 'SAV',
    service: 'Services'
  },
  Menu: {
    dashboard: 'Tableau de bord',
    customers: 'Clients',
    mySmartBattery: 'My Smart Battery',
    company: 'Entreprise',
    tools: 'Outils',
    support: 'Assistance',
    myAccount: 'Mon Compte',
    proPortal: 'Portail Pro',
    proSizer: 'My Prosizer',
    disconnect: 'Déconnecter'
  },
  Support: {
    coordinates: 'Nos coordonnées :',
    email: 'Email',
    franceCustomerSupportNumber: '0 800 710 226',
    franceTechnicalSupportNumber: '04 69 84 42 94',
    france: 'Téléphone France',
    switzerland: 'Téléphone Suisse',
    switzerlandCustomerSupportNumber: '+41 58 255 11 71',
    switzerlandTechnicalSupportNumber: '+41 58 255 11 71',
    supportCustomerEmail: 'support@mylight150.com',
    supportTechnicalEmail: 'support-pro@mylight150.com',
    technicalTitle: 'Support technique pro',
    customerTitle: 'Support technique particuliers',
    subscriptionPending: 'subscriptionPending',
    subscriptionDone: 'Le compte est bien activé.',
    devicesNotInstalled: 'devicesNotInstalled',
    loginAsLabel: 'Afficher sur MYL2.0',
    workingHours: '9h00 - 12h30 et 13h30 - 18h00\ndu lundi au vendredi',
    pairingToolkit: "Outils d'appairage",
    access: `Accedez à l'outil`,
    documentationTitle: 'Documentation',
    technicalDocumentation: 'Documentation technique',
    gettingStartedGuide: 'Guide de démarrage',
    faq: 'FAQ'
  },
  Common: {
    addressNotFound: 'Adresse non trouvée',
    email: 'Email',
    emailAddress: `Adresse email`,
    support: 'Assistance',
    MAINTAINER: 'Vendeur',
    ADMINISTRATOR: 'Administrateur',
    type: 'Type',
    INSTALLER: 'Installateur',
    goBackLink: "Retour à l'écran d'accueil",
    from: 'De',
    to: 'à',
    sure: 'Êtes-vous sûr?',
    zipCode: 'Code postal',
    country: 'Pays',
    city: 'Ville',
    address: 'Adresse',
    additionalAddress: "Complément d'adresse",
    select: 'Selectionnez...',
    selectError: 'La valeur sélectionnée est incorrecte',
    save: 'Enregistrer',
    connect: 'Connecter',
    checkAddress: "Rechercher l'adresse",
    yes: 'Oui',
    no: 'Non',
    cancel: 'Annuler',
    add: 'Ajouter',
    remove: 'Retirer',
    associate: 'Associer',
    next: 'Suivant',
    choseAddress: 'Choisissez une adresse',
    addressError: 'Adresse invalide',
    emailTaken: 'Cet email est déjà utilisé',

    tenantError: 'Tenant obligatoire',
    maintainerError: 'Vendeur obligatoire',
    zipCodeError: 'Format invalide',
    cityError: 'Format invalide',
    countryError: 'Format invalide',

    firstNameError: 'Prénom invalide',
    lastNameError: 'Nom invalide',
    phoneError: 'Numéro de téléphone requis',
    emailAddressError: "Format de l'email invalide",
    birthDateError: 'Date invalide, format attendu : ',

    createCustomerTitle: 'Ajouter un client',
    geoLocation: 'Géolocalisation',

    afterCreateModalTitle: 'Le client a bien été créé',
    phoneRequired: 'Téléphones* (Merci de renseigner au moins un telephone)',

    goToFinishInstallation: 'Inscrire le matériel',
    goToIdentity: 'Voir la fiche',
    tip: 'Astuce!',
    plus: 'Plus',
    minus: 'Moins',
    day: 'Jour',
    month: 'Mois',
    year: 'Année',
    redirecting: 'Redirection ...',
    warning: 'Attention',
    loadError: 'Erreur lors du chargement des données',
    retry: 'Réessayez',
    agree: `J'ai compris`,
    invalid: 'Invalide',
    disconnect: 'Déconnecter',
    listFilter_status_no: 'Compte client non actif',
    listFilter_status_pending: 'En cours',
    listFilter_status_ok: 'Terminé',
    listFilter_msbStatus_no: 'Non',
    listFilter_msbStatus_pending: 'En cours',
    listFilter_msbStatus_ok: 'Active',
    loading: 'Chargement...',
    noInstallation: `Installation non terminée`,
    none_result: 'Aucun résultat',
    send: 'Envoyer',
    chooseFile: 'Sélectionner le fichier',
    close: 'Fermer',
    new: 'Nouveau!',
    fetchMore: 'Afficher 10 clients supplémentaires',
    noMoreResults: 'Pas plus de résultats',
    or: 'ou',
    step: 'Étape',
    nextStep: 'Étape suivante',
    prevStep: 'Étape précédente',
    unknown: 'Inconnu'
  },
  Installation: {
    [GridType.SINGLE_PHASE]: 'Monophasé',
    [GridType.THREE_PHASE]: 'Triphasé',
    systemOffline: 'Installation déconnecté',
    saveInstallation: "enregistrer l'installation",
    pdl: 'Numéro de point de livraison',
    pdlWithMsbNotEditable:
      "Le point de livraison n'est pas modifiable car un produit My Smart Battery est opérationnel pour ce client",
    pdlWithMsbInfo:
      'Un point de livraison Enedis est requis car un produit My Smart Battery est enregistré pour ce client',
    pdlForMsbInfo: 'Un point de livraison Enedis est requis',
    pdlIsNotUnique: 'Ce PDL est déjà associé à un client',
    invalidPdlFormat:
      'Le numéro de point de livraison doit contenir entre 5 et 18 caractères',
    invalidPdlFormatForEnedis:
      'Le numéro de point de livraison Enedis doit contenir 14 chiffres',
    invalidPdlFormatForStrasbourgElectricity:
      'Le numéro de point de livraison Electricité de Strasbourg doit au format XXXXX/E1/XXXXXXX XX',
    invalidInjectionPdlFormatForStrasbourgElectricity:
      "Le PDL d'injection est au format suivant : XXXXX/P1/XXXXXXX et doit contenir 16 caractères",
    invalidMsbSnFormat: 'Le format du numéro de série est incorrect',
    invalidCaeFormat: 'Le numéro CAE doit contenir 10 chiffres',
    invalidEnedisConnectNumberFormat:
      'Le numéro Enedis Connect est un numéro au format suivant : XXXXP4EXXXXXX et doit contenir 13 caractères',
    invalidConsuelNumberFormat: 'Le numéro Consuel doit contenir 14 caractères',
    invalidConsuelDeclaredPowerFormat:
      'La puissance declaré doit être une valeur supérieur à 0',
    invalidConnectionPowerFormat:
      'La puissance de raccordement doit être une valeur supérieur à 0',
    invalidConnectionPowerInjectionPower:
      'La puissance maximale que l’installation peut injecter sur le réseau est limitée à 6 kVA en monophasé',
    invalidConnectionPowerInjectionPowerHeader: 'Valeur de puissance de connexion invalide',
    tooHighConnectionPowerInjectionPower: 'La puissance de raccordement ne peut pas excéder la puissance installée déclarée (Consuel)',
    invalidStrasbourgInjectionPdlNumberFormat: '',
    start: 'De :',
    end: 'À :',
    startComeBeforeEndWarning:
      "L'heure de fin doit être postérieur à la L'heure de début",
    hcRangeOverlapWarning: 'Les périodes ne doivent pas se chevaucher',
    masterTypeInternetConnection:
      'Comment la Master est elle connectée à internet?',
    simRouterReference: `Référence du Routeur`,
    simSerialNumber: `Numéro de série de la carte SIM`,
    ethernetConnectionType: 'Connexion direct (avec câble Ethernet)',
    wifiConnectionType: 'Connexion via un répéteur wifi',
    plc_mylightConnectionType: 'Connexion via une prise CPL Mylight (COM 1)',
    plc_otherConnectionType: 'Connexion via une prise CPL d’une autre marque',
    gsmConnectionType: 'Connexion via modem cellulaire (Routeur GSM)',
    sellingPower: 'Vente de surplus',
    errorSellingPower: `Le prix de l'énergie est incorrect`,
    unableToSellPowerWithMSB: `Le vente d’électricité n'est pas possible lorsque l'utilisateur possède une My Smart Battery`,
    errorPanelCount: `Le nombre de module doit être supérieure à 0`,
    errorPanelWattPeak: `La puissance unitaire doit être supérieure à 0`,
    warningResetFunctionCompositeDevice: `Attention, la modification du type d'installation peut entraîner la réinitialisation des fonctions de certains appareils déjà configuré`,
    areYouSureToChangedGridType: `Etes-vous sûr d'avoir sélectionné le bon type d'installation, cette action peut entraîner la réinitialisation des fonctions de certains appareils déjà configuré. Veuillez vérifier la configuration des fonctions de vos appareils avant de finaliser l’inscription.`,
    france: 'France',
    swiss: 'Suisse'
  },
  InstallationEdit: {
    title: "Edition de l'installation",
    saveEditButton: 'Enregistrer',
    savedMessage: 'Installation enregistré avec succès'
  },
  404: {
    notFoundCode: '404',
    notFound: "Nous n'avons pas trouvé la page",
    lostSentence:
      "Il semblerait qu'une erreur soit survenue, ou que vous vous soyez perdu(e).",
    backHome: "Et si vous retourniez à l'accueil ?"
  },
  LoginAs: {
    loginAsLink: 'Vue client'
  },
  ForgotPassword: {
    forgotPasswordLink: 'Mot de passe oublié ?',
    introductionMsg:
      'Merci de saisir votre adresse email et nous vous enverrons un message contenant un lien pour renouveler votre mot de passe.',
    emailSendMsg:
      'Un e-mail vient de vous être envoyé à cette adresse. Il contient un lien qui vous permettra de regénérer votre mot de passe.',
    error: 'Impossible de contacter le serveur, veuillez réessayer plus tard.',
    resetButton: 'Changer de mot de passe'
  },
  Tenant: {
    myhome: 'Mylight',
    myhomeFullName: 'mylight150',
    domuneo: 'Domuneo',
    domuneoFullName: 'Domuneo – MyLight Inside',
    planeteoui: 'Planète Oui',
    planeteouiFullName: 'Planète OUI – MyLight Inside',
    mypower: 'Engie My Power',
    mypowerFullName: 'Engie My Power – MyLight Inside'
  },
  DeviceType: {
    mst: 'Master - MST',
    mst_g3: 'Master - MG3',
    eth: 'Smartplug Ethernet - ETH',
    sw: 'Smartplug Switch - SW',
    sw_g3: 'Relay - MG3',
    gmd: 'Compteur - GMD',
    gmd_g3: 'Compteur - MG3',
    enp: 'Enphase - ENP',
    cmp: 'Compteur - CMP',
    wsrhr: 'Wiser Relai',
    wsrts: 'Thermostat Wiser',
    wsrvl: 'Valve Wiser',
    tdac: 'Climatisation Tado',
    tdht: 'Thermostat Tado',
    tph: 'Contrôleur - TPH',
    tado_ac: 'Climatisation Tado',
    tado_heater: 'Thermostat Tado',
    rs485: 'Compteur RS485',
    bat: 'Batterie',
    modbus_box: 'Coffret pré-câblé (UPM)',
    modbus_part: 'Pièce détachée'
  },
  CustomerEdit: {
    title: 'Modification client',
    saveEditButton: 'Enregistrer',
    firstName: 'Prénom',
    lastName: 'Nom',
    phoneNumber: 'Téléphone fixe',
    mobileNumber: 'Téléphone Mobile',
    zipCode: 'Code postal',
    city: 'Ville',
    editCustomerSuccess: 'Client édité avec succès'
  },
  Messages: {
    deviceReadingsIncomplete:
      'Nous avons constaté un comportement atypique de l’installation. Le compteur ne communique plus de données de production / consommation.\n\nLes valeurs présentées ne correspondent pas à la production / consommation réelle.\n\nSi le problème persiste veuillez contacter notre service technique.\n'
  },
  Dashboard: {
    numberOfInstallation: 'Installations',
    operational: 'Opérationnelles',
    pending: "En attente d'activation",
    prosizerCallToAction:
      "DÉCOUVREZ LE LOGICIEL DE SIMULATION ÉNERGÉTIQUE À LA POINTE DE L'INNOVATION",
    prosizerLinkName: 'ACCÉDEZ AU PROSIZER'
  },
  Diagnostic: {
    startButton: 'Lancer les T48',
    incorrectPhase:
      'Impossible de lancer les tests sur cette installation, PHASE non renseigné',
    diagnosticStatusPending: 'En cours'
  },
  DiagnosticSummary: {
    summaryTitle: 'Tests',
    testNameLabel: 'Nom',
    testSetStatus: 'État',
    pending: 'En cours',
    ok: 'Terminé',
    Failure: 'Erreur',
    create: 'Créer',
    testErrorLabel: 'ERR',
    testOkLabel: 'OK',
    date: 'Date',
    emptyTestList: 'Aucun test',
    details: 'DETAILS',
    menuTitle: 'Tests',
    emptyTestDetailsList: 'Aucun résultat',
    Error: 'Erreur',
    Causes: 'CAUSE PROBABLE',
    Solutions: 'SOLUTION(S)'
  },
  MySmartBattery: {
    battery: 'My Smart Battery',
    addBattery: 'Ajouter une My Smart Battery',
    addBatteryInfo: `
      <p class="m-0">Vous aurez besoin des informations suivantes :</p>
      <ul class="pl-4">
        <li>Numéro de série</li>
        <li>Numéro de PDL</li>
        <li>Régie</li>
      </ul>
    `,
    addBatteryELD: 'Puis renseigner les détails de mise en service',
    addBatteryELDInfo:
      "Les informations à renseigner diffèrent selon le GRD concerné",
    addBox: 'Ajouter',
    createBattery: 'Enregistrer',
    updateBattery: 'Enregistrer',
    deleteBattery: 'Retirer',
    caeNumber: 'Numéro de CAE',
    pdlNumber: 'Numéro de point de livraison',
    enedisConnect: 'Enedis Connect',
    enedisConnectNumber: 'Enedis Connect',
    consuelNumber: 'Numéro Consuel',
    consuelDeclaredPower: 'Puissance installée déclarée (Consuel)',
    consuelDeclaredPowerTooltip: 
      "Puissance totale maximale que l’installation peut produire.",
    connectionPower: 'Puissance de raccordement en injection.',
    connectionPowerTooltip:
      "Puissance maximale que l’installation peut injecter sur le réseau (limitée à 6 kVA en monophasé). NB: La puissance de raccordement ne peut pas excéder la puissance installée.",
    strasbourgInjectionPdlNumber: 'PDL d’injection',
    editConsuelNumberTitle: 'Consuel et consentement',
    editConsuelNumberInfo: `L'installation de production est achevée et raccordée au tableau électrique du local ?
       Le producteur dispose bien de l'autorisation d'urbanisme nécessaire à l'implantation de l'installation de production (permis de construire, non opposition à la déclaration préalable...) ou atteste qu'elle n'en nécessite pas ?
       Les éléments collectés dans l'affaire Enedis-Connect sont toujours conformes à la demande ?
       L'unité de production d'électricité qui fait l'objet de cette demande de mise en service est confrme aux prescription du règlement CE2016/631, de l'arrêté relatif au raccordement du 9 juin 2020 et de la DTR d'Enedis
       Si la MES se fait sans déplacement d'un technicien Enedis, le producteur s'engage à procéder au test de la protection de découplage : en présence si besoin de l'installateur, après couplage de l'installation, ouverture du disjoncteur Enedis et vérifcation du découplage`,
    editConsuelNumberCheckbox: 'Je valide que mon installation est conforme',
    energyProvider: `Fournisseur d'énergie`,
    mylightEnergyProvider: `mylight150`,
    domuneoEnergyProvider: `Domuneo`,
    planeteOuiEnergyProvider: `Planète Oui`,
    caeNumberShort: 'N°CAE',
    addSerialNumber: 'Ajouter un numéro de série My Smart Battery',
    enterSerialNumber: 'Saisir le numéro de série de la Smart Battery',
    snLimit: 'Maximum 5 Boxes sont autorisés',
    snExists: 'Ce numéro de série a déjà été ajouté',
    invalidSN: `Numéro de série inconnu. Veuillez le modifier`,
    scan: 'Scanner le numéro',
    serialNumber: 'Numéro de série',
    serialNumberBlank: '100 kWh:  ',
    serialNumbers_PLANETE_OUI: 'Numéros de série My Smart Battery',
    serialNumbers_MYLIGHT: 'Numéro de série My Smart Battery',
    volume: 'Volume de la batterie',
    volumeShort: 'Capacité',
    status: 'Statuts',
    pdl: 'Numéro de point de livraison',
    pdlError: 'Numéro de Point de Livraison invalide',
    caeNumberError: 'Numéro CAE invalide',
    alreadyRegisteredSN: `Le numéro de série XXXXXXXXXX est déjà associé. Veuillez le modifier.`,
    whereIsTheMylightSNQuestion: `Où trouver le numéro de série de MySmartBattery ?`,
    whereIsTheMylightSNAnswer: `C'est le numéro de série au dos du modem cellulaire ou sur le carton.`,
    whatIsEanFormat: `Quel est le format du Point de Livraison ?`,
    EnedisFormat: `Format pour Enedis : 19135311092931`,
    EsFormat: `Format pour Électricité de Strasbourg : 67000/E1/1415171 56`,
    warning: `Attention !`,
    EanImportantStep: `Le remplissage du PDL est une étape cruciale. Si une erreur est commise lors de sa saisie, cela entrainera un allongement du délai d’activation de la My Smart Battery`,
    addingBoxesError: `Le numéro de série saisi n'existe pas ou est déjà utilisé. Veuillez le corriger ou en utiliser un autre`,
    msbSNFilled_PLANETE_OUI: 'Saisie numéros de série',
    msbSNFilled_MYLIGHT: 'Saisie numéro de série',
    msb_SN_filled_true_PLANETE_OUI: 'Les numéros de série ont été saisis',
    msb_SN_filled_false_PLANETE_OUI:
      'Les numéros de série n’ont pas encore été saisis',
    msb_SN_filled_true_MYLIGHT: 'Le numéro de série a été saisi',
    msb_SN_filled_false_MYLIGHT: `Le numéro de série n'a pas encore été saisi`,
    msb_SN_filled_details_PLANETE_OUI:
      'Ce statut indique si le(s) numéro(s) de série de la My Smart Battery a (ont) été saisi.',
    msb_SN_filled_details_MYLIGHT:
      'Ce statut indique si le numéro de série de la My Smart Battery a été saisi.',
    msbACFilled: "Saisi code d'activation",
    msb_AC_filled_true_PLANETE_OUI: 'Les codes d’activation ont été saisis',
    msb_AC_filled_false_PLANETE_OUI:
      'Les codes d’activation n’ont pas encore été saisis',
    msb_AC_filled_details_PLANETE_OUI:
      'Ce statut indique si le client a saisi le(s) code(s) d’activation(s) de la My Smart Battery dans son interface web MyHome.',
    msbProviderRegistrationOk: "Signature contrat d'energie",
    msb_provider_registration_ok_true_PLANETE_OUI:
      'Le contrat de fourniture d’électricité a été signé',
    msb_provider_registration_ok_false_PLANETE_OUI:
      'Le contrat de fourniture d’électricité n’a pas encore été signé',
    msb_provider_registration_ok_details_PLANETE_OUI:
      'Ce statut indique si le contrat de fourniture d’énergie a été signé par le client. Cette information est mise à jour automatiquement à la signature du contrat.',
    msbEnedisOk: 'Raccordement Enedis',
    msb_enedis_ok_true_PLANETE_OUI: 'La demande de raccordement a été validé',
    msb_enedis_ok_false_PLANETE_OUI:
      'La demande de raccordement est en attente de validation',
    msb_enedis_ok_details_PLANETE_OUI:
      'Ce statut indique si le point de livraison du client a bien été rattaché au périmètre d’équilibre du fournisseur d’énergie. Cette information est mise à jour automatiquement.',
    msbOk: 'Activation',
    msb_ok_true_PLANETE_OUI: 'La Smart Battery est opérationnelle',
    msb_ok_false_PLANETE_OUI:
      'La Smart Battery n’est pas encore opérationnelle',
    msb_ok_true_MYLIGHT: 'La Smart Battery est opérationnelle',
    msb_ok_false_MYLIGHT: 'La Smart Battery n’est pas encore opérationnelle',
    arpeTitle: 'ARPE',
    sendingARPEDate: 'ARPE',
    caeTitle: 'CAE',
    consuelTitle: 'Consuel',
    planeteOuiMsbDocumentsTitle: `Documents`,
    mylightMsbDocumentsTitle: `Demande de Mise en Service en injection`,
    planeteOuiMsbDocumentsInfo: `Afin de compléter le dossier et activer la My Smart Battery, vous devez fournir l'ARPE signé ainsi que le numéro de CAE au fournisseur d'énergie. Vous pouvez télécharger le modèle d'ARPE et télétransmettre les documents sur ce portail. Vous pouvez saisir le numéro de CAE en cliquant sur le bouton d’édition de la My Smart Battery. Une fois l’ARPE télétransmis et le numéro de CAE fourni ces derniers seront envoyés au fournisseur d'énergie.`,
    mylightMsbDocumentsInfo: `Pour que nous puissions procéder à la mise en service en injection auprès du GRD (ex : ENEDIS) et ainsi activer la MySmartBattery correspondante, vous devez saisir dans l’espace MySmartBattery le numéro de série de la MySmartBattery, le numéro de la demande de raccordement effectuée auprès du GRD (ex : ENEDIS CONNECT), le numéro de CONSUEL et nous joindre le document PDF du CONSUEL via l’espace prévu ci-dessous. Nous effectuerons ensuite cette demande dans les meilleurs délais.`,
    mylightMsbDocumentsAdditionalInfo: ` Si le GRD du site ne permet pas la gestion par nos soins de la demande de mise en service en injection, nos équipes supports vous contacterons pour étudier les modalités spécifiques d’activation.`,
    arpeInfo: `Téléchargez le modèle de document afin de le faire signer par le client. Une fois le document signé, télétransmettez-le au travers de ce portail.`,
    caeInfo: `Télétransmettez le CAE au fournisseur d'énergie au travers de ce portail.`,
    consuelInfo: `Merci de nous transmettre le CONSUEL en version PDF. Cette étape est indispensable pour pouvoir procéder à la demande de Mise en Service en injection auprès du GRD.`,
    arpeDownload: `Télécharger le Modèle ARPE`,
    arpeUpload: `Télétransmettre l'ARPE signé`,
    caeUpload: `Télétransmettre le CAE`,
    consuelUpload: `Télétransmettre le Consuel`,
    choosePdf: 'Choisir un document au format PDF',
    importFilePdfFormat: 'Importer le Consuel au format PDF',
    importARPEPdfFile: 'Importer l\'ARPE au format PDF',
    pdfUploadInfo_ARPE: '',
    pdfUploadInfo_CAE: '',
    pdfUploadInfo_CONSUEL: `Avant de nous transmettre votre attestation Consuel, veuillez la relire et vous assurer qu'elle comporte:
    Le bon numéro AC Consuel
    La bonne référence de point de livraison  (PDL)
    Les bonnes informations de contact de votre client
    Cliquez ensuite sur Envoyer en bas de cette fenêtre`,
    needPdf: 'Veuillez choisir un fichier au format PDF',
    pdfSendingError: `Erreur lors de l'envoi du fichier`,
    pdfSendingSuccess: 'Le fichier a été envoyé avec succès',
    sendDateCAE: 'Envoi CAE',
    sendDateARPE: 'Envoi ARPE',
    sendDateCONSUEL: 'Envoi CONSUEL',
    notSending: 'Non envoyé',
    date: 'date',
    contractNotSending: 'Contrat non envoyé',
    contractNotSendingInformationMessage:
      "Le contrat sera automatiquement envoyé lorsque le client aura saisi le(s) code(s) d'activation(s) de la My Smart Battery lors de l'activation de son compte.",
    sendDateEnergyContract: `Date d'envoi du contrat de fourniture d'énergie`,
    listFilter_msbCaeNumber_isNotNull: `Saisi`,
    listFilter_msbCaeNumber_isNull: `Non saisi`,
    listFilter_msbEnedisConnectNumber_isNotNull: `Saisi`,
    listFilter_msbEnedisConnectNumber_isNull: `Non saisi`,
    listFilter_msbConsuelNumber_isNotNull: `Saisi`,
    listFilter_msbConsuelNumber_isNull: `Non saisi`,
    listFilter_msbSendingARPEDate_isNotNull: `Envoyé`,
    listFilter_msbSendingARPEDate_isNull: `Non envoyé`,
    listFilter_msbSNFilled_isNotNull: `Saisi`,
    listFilter_msbSNFilled_isNull: `Non saisi`,
    listFilter_msbACFilled_isNotNull: `Saisi`,
    listFilter_msbACFilled_isNull: `Non saisi`,
    listFilter_msbEnedisOk_isNotNull: `Ajouté au périmètre d'équilibre`,
    listFilter_msbEnedisOk_isNull: `Non ajouté au périmètre d’équilibre`,
    listFilter_msbProviderRegistrationOk_isNotNull: `Contrat de fourniture d'énergie signé`,
    listFilter_msbProviderRegistrationOk_isNull: `Contrat de fourniture d’énergie non signé`,
    listFilter_msbOk_isNotNull: `Smart Battery oprationelle`,
    listFilter_msbOk_isNull: `Smart Battery non operationnelle`,
    listFilter_msbType_isNull: `Planète Oui`,
    listFilter_msbType_isNotNull: `mylight150`,
    msbEmptyList: `Aucun Smart Battery`,
    msbIcon: 'MSB',
    whereIsSN: 'Où se situe le numéro de série My Smart Battery?',
    snLocation: `Vous pouvez trouver le numéro de série à l'arrière de la boite My Smart Battery sous le code barre`,
    serialNumbersEnteredMoreThan30DaysAgo_PLANETE_OUI: `Attention ! Les numéros de série de cette batterie ont été saisis il y a plus de 30 jours.`,
    serialNumbersEnteredMoreThan30DaysAgo_MYLIGHT: `Attention ! Le numéro de série de cette batterie a été saisi il y a plus de 30 jours.`,
    msbStatus: {
      activated: 'activée le',
      created: 'enregistrée le'
    },
    enedisGridInjection: {
      fill: 'à remplir',
      inProgress: 'en cours',
      toValidate: 'à valider',
      requested: 'demandée le',
      enabled: 'activée le'
    },
    strasbourgElectricityAdministrativeProcedure: {
      fill: 'à remplir',
      inProgress: 'en cours',
      toValidate: 'à valider',
      finished: 'terminée le'
    },
    enteredOn: 'Saisi le',
    enteredOnFormatDate: 'DD/MM/YYYY',
    onHold: 'En attente',
    energyDistributors: {
      ENEDIS: 'Enedis',
      ELECTRICITE_STRASBOURG: 'Électricité de Strasbourg'
    },
    energyDistributor: 'Régie',
    networkInjection: 'Mise en injection',
    errorLoadingMsbActivation:
      'Une erreur est survernue lors du chargement des données lié à la MySmartBattery',
    uploadFile: 'Uploadez votre fichier',
    validate: 'Valider',
    administrativeProcedure: 'Démarche administrative',
    forActivatedMySmartBattery:'Pour activer la MySmartBattery de votre client nous avons besoin de récupérer l’accord de rattachement au périmètre (ARPE) ainsi que le numéro de CAE du site pour lequel vous allez établir la demande de raccordement.',
    downloadARPEModele:'Téléchargez le modèle de document ARPE ci-dessous avant de nous le transmettre une fois signé',
    deletionModalTitle: 'Supprimer la MSB enregistrée',
    deletionModalBodyTitle: 'Attention !',
    deletionModalBodyContent: 'Vous êtes sur le point de supprimer la MSB précédemment enregistrée. Toutes les informations renseignées seront perdues (n° de série, PDL, ...).',
    deletionButton: 'Supprimer la MSB',
    deletionCancelButton: 'Annuler',
    deletionSuccess: 'MSB a été supprimé avec succès',
    enedisGridInjectionFormDescription: 'Pour finaliser la mise en service en injection, merci de remplir l\'ensemble des champs ci-dessous et de nous télétransmettre le PDF du Consuel.',
    enedisGridInjectionFormEnedisConnectFieldDescription: 'Saisir le numéro à 13 caractères au format suivant : XXXXP4EXXXXXX',
    enedisGridInjectionFormConsuelNumberFieldDescription: 'Saisir le numéro à 14 chiffres',
    enedisGridInjectionFormDeclaredInstalledPowerFieldDescription: 'Saisir la valeur renseignée dans le Consuel',
    enedisGridInjectionFormConnectionPowerFieldDescription: 'Saisir la valeur renseignée dans la demande Enedis Connect',
    strasbourgElectricityAdministrativeProcedureCaeNumberFieldDescription: 'Saisir le numéro à 10 chiffres',
    strasbourgElectricityAdministrativeProcedureInjectionPdlNumberFieldDescription: 
      'Saisir le numéro à 16 caractères : XXXXX/P1/XXXXXXX',
    errors: {
      serialNumberFormatNotValid: 'Le format du numéro de série est incorrect',
      serialNumberAlreadyUsed:
        'Ce numéro de série est deja utilisé sur une autre installation',
      serialNumberDoesntExist: "Ce numéro de série n'existe pas",
      updateSerialNumberFailed:
        'Une erreur est survenue lors de la mise à jour du numéro de série de la MySmartBattery',
      pdlNumberFormatNotValid: 'Le format du point de livraison est incorrect',
      pdlNumberAlreadyUsed:
        'Ce numéro de point de livraison est deja utilisé sur une autre installation',
      updatePdlNumberFailed:
        'Une erreur est survenue lors de la mise à jour du numéro de point de livraison',
      requestEnedisGridInjectionFailed:
        'Une erreur est survenue lors de la demande de mise en injection',
      requestStrasbourgElectricityAdministrativeProcedureFailed:
        "Une erreur est survenue lors de l'envoi des documents administratifs pour Electricité de Strasbourg",
      createMsbFailed:
        "Une erreur est survenue lors de l'ajout de la MySmartBattery",
      eldFormatInvalid: 'Le format du numéro de point de livraison est invalide',
      msbApiUpdateStrasbourgInjectionPdlNotUnique: 
        "Ce numéro de point de livraison d'injection est déjà utilisé sur une autre installation",
    }
  },
  BarcodeReader: {
    scan: 'Scanner',
    rescan: 'Rescanner',
    scanTitle: 'Scan du code barre',
    scanInstruction: "Veuillez centrer le code barre au milieux de l'image",
    wrongCode: 'Code non reconnu',
    correctCode: 'Le suivant code a été reconnu:',
    UCGHelp: `Scannez le numéro de série du coffret.
    Le code barre du numéro de série du coffret est visible :

    - Sur l’étiquette du carton du coffret
    - Sur la porte intérieure du coffret`,
    MSTHelp: `Scannez le numéro d'activation ou l'adresse MAC de la MST.
      Le code barre du numéro d'activation ou de l'adresse MAC de la MST est visible :

      - Sur l’étiquette du carton de la MST (modèle V2)
      - Sur la face arrière de la MST (modèle V2)
      - Sur la MST dans le coffret`,
    MSBHelp: `Scannez le code barre situé sur la porte intérieure du coffret.
    Le code barre du numéro de série du coffret est disponible :

    - Sur l’étiquette du carton du coffret
    - Sur la porte intérieure du coffret\``
  },
  TestTitle: {
    'Test MST to the server connection':
      'Test de connection de la Master au serveur',
    'GMD communication test': 'Test de communication de GMD vers Master',
    'TPH communication test': 'Test de communication de TPH vers Master',
    'ETH communication test': 'Test de communication de ETH vers Master',
    'SW communication test': 'Test de communication de SW vers Master',
    'Test water heater consumption on TPH':
      'Test de commande du chauffe-eau par TPH',
    'Test Single Phase Consumption Measurement':
      'Test de mesure de la consommation d’une installation monophasée',
    'Test single-phase Production measurement':
      'Test de mesure de la production d’une installation monophasée'
  },
  TestErrorCauses: {
    COM030: 'Pas de connexion internet ou mauvaise connexion internet.',
    COM032: 'Mauvais signal CPL ou GMD instable',
    COM033: 'Mauvais signal CPL',
    COM034: 'Mauvais signal CPL',
    COM035: 'Mauvais signal CPL',
    RCE050:
      "Le chauffe eau ne semble pas raccordé ou mal raccordé à l'appareil TPH",
    TCM010_01: 'La pince de mesure n’est pas branchée ou mal positionnée',
    TCM010_02: 'la consommation est négative',
    TCM010_03: 'La pince de mesure est mal positionnée',
    TCM011_01: 'La pince de mesure n’est pas branchée ou mal positionnée',
    TCM011_02: 'La pince de mesure est à l’envers',
    TCM011_03: 'La pince de mesure est mal positionnée'
  },
  TestErrorMessage: {
    COM030: 'Pas de connexion internet ou mauvaise connexion internet.',
    COM032: "L'appareil GMD %MAC% semble mal communiquer avec la prise Master",
    COM033: "L'appareil TPH %MAC% semble mal communiquer avec la prise Master",
    COM034: "L'appareil ETH %MAC% semble mal communiquer avec la prise Master",
    COM035: "L'appareil SW %MAC% semble mal communiquer avec la prise Master",
    RCE050:
      "Le chauffe eau ne semble pas raccordé ou mal raccordé à l'appareil TPH %MAC%",
    TCM010_01:
      "Aucune mesure de consommation n'a été observée. La pince de mesure est probablement pas branchée ou mal positionnée",
    TCM010_02:
      "La consommation mesurée est négative. La pince de mesure est probablement à l'envers ou mal positionnée.",
    TCM010_03:
      'La mesure de la production indique une production de nuit. La pince de mesure est probablement mal positionnée.',
    TCM011_01:
      "Auncune production n'a été mesurée. La pince de mesure semble est probablement mal branchée ou mal positionnée",
    TCM011_02:
      "La production mesurée est negative. La pince de mesure est probablemnet à l'envers ou mal positionnée.",
    TCM011_03:
      'La mesure de la production indique une production de nuit. La pince de mesure est probablement mal positionnée.'
  },
  TestErrorSolutions: {
    COM030:
      '• Vérifier la connexion internet\n• Veuillez contacter le support\n',
    COM032:
      '• Déplacer l’alimentation du GMD (la sortir du coffret)\n• Appeler le support\n',
    COM033: '• Appeler le support\n',
    COM034: '• Prise debranchée\n• Appeler le support\n',
    COM035: '• Prise debranchée\n• Appeler le support\n',
    RCE050: '• Appeler le support\n',
    TCM010_01:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n',
    TCM010_02:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n',
    TCM010_03:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n',
    TCM011_01:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n',
    TCM011_03:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n',
    TCM011_02:
      '• Vérifier la position de la pince de mesure\n• Appeler le support\n'
  },
  UCG: {
    numberOfInstallation: 'Installations',
    operational: 'Opérationnelles',
    pending: "En attente d'activation",
    doesNotHaveUCG: "Je n'ai pas d'UCG",
    iHaveUCG: 'Je possède un UCG',
    noUCG: "Je n'ai pas d'UCG",
    ucg: "Numéro de serie de l'UCG",
    scan: 'Lecteur de code barre',
    scanTitle: "Scan du code barre de l'UCG",
    ucgHelp: `Saisissez le numéro de série du coffret.
    Le numéro de série du coffret est visible :

    - Sur l’étiquette du carton du coffret
    - Sur la porte intérieure du coffret`,
    ucgHelpMobile: `Saisissez ou scannez le numéro de série du coffret.
    Le code barre du numéro de série du coffret est visible :

    - Sur l’étiquette du carton du coffret
    - Sur la porte intérieur du coffret`,
    writeUCG: `Veuillez saisir le numéro de série de votre coffret mylight150.`,
    wrongUCG: `Le numéro de coffret saisie n'est pas reconnu. Veuillez vérifier son exactitude`,
    whereIsUCG: `Où se situe le numéro d'UCG ?`,
    positionUCG: `Vous pouvez trouver le numéro de série à l’intérieur du plastron du coffret en bas à gauche ou sur le carton.`,
    ucgPreconfigurationWizardInfo: `A l’étape 6 vous pourrez exécuter la configuration automatique des appareils de votre coffret mylight150 en saisissant le numéro de série de votre UCG (Unité Centrale de Gestion). Cette assistant configurera automatiquement les appareils de votre coffret mylight150 et leurs fonctions. Uniquement valable pour les UCG.`,
    ucgPreconfigurationWizardAdditionalInfo: `L'assistant de configuration permet de configurer automatiquement les appareils de votre coffret mylight150 ainsi que leurs fonctions. Si le coffret contient des appareils RS485, ceux-ci seront automatiquement ajoutés. Les appareils CPL doivent être appairés auparavant.`
  },
  UPM: {
    whereIsSerial: 'Où se situe le numéro de série ?',
    positionSerial:
      'Vous pouvez trouver le numéro de série sur le devant du coffret en bas à gauche.'
  },
  WModbusPart: {
    whereIsSerial: 'Où se situe le numéro de série ?',
    positionSerial:
      "Vous pouvez trouver le numéro de série sur l'étiquette, au dos du composant."
  },
  Master: {
    explainBarCodeReader:
      "Veuillez centrer le code barre au milieu de l'image. ",
    barCodeList: 'Code bar déchiffré :',
    master: "Code d'activation/adresse MAC Ethernet de la Master",
    masterHelp: `Saisissez le numéro d'activation ou l'adresse MAC de la MST.
    Le code barre du numéro d'activation ou de l'adresse MAC de la MST est visible :

    - Sur l’étiquette du carton de la MST
    - Sur la face arrière de la MST
    - Sur la MST dans le coffret`,
    masterHelpMobile: `Saisissez ou scannez le numéro d'activation ou l'adresse MAC de la MST.
    Le code barre du numéro d'activation ou de l'adresse MAC de la MST est visible :

    - Sur l’étiquette du carton de la MST
    - Sur la face arrière de la MST
    - Sur la MST dans le coffret`,
    MAC: 'Adresse MAC',
    MSTCODE: "Code d'activation",
    sMAC: "Vous avez saisie l'adresse Mac de la Master",
    sMSTCODE: "Vous avez saisie le code d'activation de la Master",
    masterError: 'Code invalide',
    scanTitle: 'Scan du code barre de la Master',
    scan: 'Lecteur de code barre',
    invalid: '(invalide)',
    timeout:
      "L'application ne parvient pas à lire le code barre. Veuillez saisir le code manuellement",
    eraseMessage:
      'Attention, changer de Master écrasera toute configuration des appareils. Voulez-vous tout de même continuer ?',
    updateMST: 'Changer de Master',
    connectedMST: 'Master connectée',
    disconnectedMST: 'Master déconnectée',
    recontactMaster: 'Recontacter la Master',
    selectTypeConnection: `Veuillez indiquer comment est-elle connectée à internet`,
    selectMasterDisconnect: `La master sélectionnée est déconnectée, Veuillez vérifier sa connexion`,
    receptionSignalNotOpti: `La réception du signal n’est pas optimale nous vous conseillons d’essayer de l’améliorer en :`,
    extendingAntenna: `Déportant l’antenne vers une fenêtre via son câble de 3m`,
    extendingModem: `Déportant le modem et l’antenne vers une fenêtre via une prise CPL mylight150 COM-1`,
    reorientAntenna: `Réorientant l’antenne`,
    contactSupport: `Contactant le support`,
    warningIntermittentConnexion: `Si vous ne modifiez rien, il est possible que vous constatiez des connexions intermittentes lors de l’utilisation du produit, mais aucune modification n’est obligatoire.`,
    informations: 'Informations:',
    informationRebootMasterWhenChangeTypeConnection: `Lorsque vous changer le type de connexion de la Master à internet, veuillez redémarrer la Master en actionnant le disjoncteur afin que cette dernière soit bien prise en compte`,
    warningMessagelowerSignalForAdapterCpl: `Le débit CPL entre la Master et la prise COM-1 semble limité, vous pouvez l’améliorer en :`,
    verifCom1ConnectToMultipleSocket: `- Vérifiant que la prise COM-1 n’est pas branchée sur une multiprise`,
    verifCom1DistanceOtherCpl: `- Vérifiant que la prise COM-1 est à plus d’un mètre d’un autre équipement CPL (voir manuel ou contacter le support technique si impossibilité d’enlever cet autre équipement)`,
    verifCom1WifiRepeater: `- Utilisant un répéteur Wifi`,
    verifCom1CellularModem: `- Utilisant un modem cellulaire`,
    verifCom1QualityElectricityNetwork: `- Vérifiant la qualité du réseau électrique (section des câbles, filtres, raccords...)`,
    adapterCplNotDetected: `Vous avez déclaré que la Master devait être connectée à Internet via une prise CPL mylight150 (COM-1) or cela ne semble pas être le cas, aucun appareil mylight150 remplissant ce rôle n'a été détecté. Merci de bien vouloir vérifier.`,
    bestPracticeGoodRateFlow: `Bonnes pratiques pour garantir un bon débit entre Master et la COM-1:`,
    com1ConnectToMultipleSocket: `- La prise COM-1 ne doit pas être branchée sur une multiprise`,
    com1DistanceOtherCpl: `- La prise COM-1 doit être à plus d’un mètre d’un autre équipement CPL`,
    warningGsmMaybeDisconnected: `Vous avez déclaré que la Master devait être connectée à Internet via un modem cellulaire (routeur GSM), or cela ne semble pas être le cas, aucun appareil remplissant ce rôle n'a été détecté. Merci de bien vouloir vérifier.`
  },
  RegistrationMenu: {
    pv_installation: 'Installation',
    partnership: 'Comptes Tiers',
    battery: 'My Smart Battery',
    ucg: 'UCG',
    master: 'Master',
    devices: 'Appareils',
    functions: 'Fonctions',
    wiring: 'Branchements',
    stepperTitle: "Finalisation de l'inscription",
    mainTitle: "Finalisation de l'inscription",
    finish: "Terminer l'installation",
    summary: 'Récapitulatif',
    devicesSummary: 'Appareils et fonctions'
  },
  DevicesInscription: {
    pleaseFinishInscription: `L'inscription de vos appareils touche à sa fin. Nous vous invitons à vérifier les informations présentes dans le récapitulatif ci-dessous avant de valider votre inscription en cliquant sur le bouton Terminer l'inscription des appareils en bas de page.`,
    skipRegistrationStep: `Ignorer l'etape`,
    finalizeInstallation: "Terminer l'inscription des appareils",
    deviceNotAssigned: 'Appareils non assignés',
    warningYouDontHaveAssignedSomeDevice:
      'Attention, vous n\'avez pas affecté de fonction à tous les appareils, leurs fonctions seront automatiquement définies à "Autre"',
    inscriptionInProgress: 'Inscription en cours de finalisation',
    notPossibleToDefineTwoGlobalConsumptionCounter:
      "Il n'est pas possible de définir 2 compteurs de consommation globale.",
    functionAlreadyAffectedAtAnOtherDevice:
      'La fonction a déjà été affectée à un autre appareil :',
    continueInscription: `Continuer l'inscription`,
    waitingFinishInscription: `L'inscription est en cours de finalisation, cette opération peut durer
    quelques minutes, merci de patienter`,
    sendInscriptionSummary: `Partager l’état de l'inscription par email`,
    sendInscriptionSummaryEmailInfo: `Vous pouvez saisir plusieurs adresses email en les séparant par un ";"`,
    sendInscriptionSummaryEmailExample: `Exemple: "first@domain.com;second@domain.com"`,
    sendInscriptionSummaryEmailFeatureInfo: `Partager l'état de l'inscription avec vos partenaires.\n En saisissant l'email de vos partenaires, un rapport récapitulatif de l'inscription et de son état sera envoyé.`
  },
  Devices: {
    counter: 'Compteur',
    activationCode: "Code d'activation",
    roomToDefine: 'Pièce à définir',
    type: 'Type',
    name: 'Nom',
    mac: 'Mac',
    room: 'Pièce',
    saved: 'enregistré',
    unsaved: 'non-enregistré',
    on: 'CONNECTÉ',
    offline: 'DÉCONNECTÉ',
    deviceState: 'État',
    stateOn: 'Allumé',
    stateOff: 'Eteint',
    alaska_home: 'Habitation',
    doneListTitle: 'Fonctions',
    todoListTitle: 'Appareils non-utilisés',
    unusedFunctions: 'Appareils avec fonctions non définies',
    emptyFunctionsList: 'Aucuns appareil sans fonction',
    emptyDoneList: 'Aucun appareil',
    roomType: 'Type de pièce',
    roomName: 'Nom de la pièce',
    deviceList: 'Appareils relié a la Master',
    firmware: 'Version du firmware',
    function: "Fonction de l'appareil",
    swG3Function: `Type d’appareil piloté`,
    defineFunction: 'Fonction à définir',
    listTitle: 'Système Complet',
    modbusDevicesTitle: 'Appareils connectés en filaire (Modbus)',
    wmodbusDevicesTitle: 'Appareils connectés sans-fil (W-Modbus)',
    modbusAddress: `Adresse Modbus`,
    modbusReference: `Référence`,
    refreshList: 'Rafraichir la liste',
    split: '3 mesures',
    merge: '1 mesure',
    functionName: 'Nom de la fonction',
    swG3FunctionName: `Nom de l'appareil piloté`,
    functionType: "Type d'appareil mesuré",
    functionEditionTitle: 'Fonctions(s)',
    affectFunction: 'Affecter une fonction',
    greenplay: 'Activer le Green Play',
    deviceEditing: `Configuration de l'appareil`,
    negativePower: `
     La mesure retournée par l'appareil est négative.
     En conditions normales, la fonction affectée à cet appareil ne doit pas être négative.
     Veuillez rafraîchir la liste et si le problème persiste vérifier la bonne pose de l'installation,
     `,
    noFunctionLabel: 'Aucune fonction assignée',
    greenPlayLabel: 'Green Play activé',
    plcType: 'CPL',
    rs485Type: 'RS485 filaire',
    addDeviceTitle: 'Ajouter un nouvel appareil',
    addDeviceType: `Quel type d'appareil?`,
    addDeviceTitlePLC: 'Ajouter un appareil CPL',
    addDeviceTitleRS485: 'Ajouter un compteur RS485 filaire',
    addDevicePLC: 'Appareil connecté en CPL',
    addDeviceRS485: 'Appareil connecté en RS485 filaire',
    addDeviceWMODBUS: 'Appareil sans-fil (W-Modbus)',
    selectWModbusDeviceType: 'Choix du modèle',
    addDeviceTitleWModbusBox: 'Ajouter un coffret pré-câblé (UPM)',
    addDeviceWModbusBoxSerial: 'Numéro de série du coffret pré-câblé',
    addDeviceTitleWModbusPart: 'Ajouter une pièce détachée',
    addDeviceWModbusPartSerial: 'Numéro de série du composant W-Modbus',
    connectedRS485Devices: 'Appareils RS485 connectés',
    chooseReference: 'Choisissez la référence',
    chooseAddress: "Choisissez  l'adresse",
    macOrAC: `Code d'activation ou l'adresse MAC CPL de la appareil`,
    invalidMacOrAC: 'Code invalide',
    plcConnectingInProgress: `L'Appareil en cours de connexion`,
    plcConnectingInProgressExtended: `La connexion peut durer de 1 à 5 minutes.
       Veuillez patienter`,
    reference: 'Référence',
    deviceDisconnectedError: `Un %deviceName% apparait deconnecté. Veuillez verifier la connexion ou le supprimer de l'inscription.`,
    disconnectedModbusDevice: `Certains appareils connectés en Modbus sont déconnectés, veuillez rafraichir la liste des appareils ou vérifier la connexion.`,
    forbiddenDeviceForGridType: `L'installation est déclarée en monophasée, alors que cet appareil est configuré pour le triphasé`,
    deletingModbusDevice: `L'appareil connecté via Modbus a été supprimé avec succès`,
    setGridType: `Vous devez renseigné le type d'installation (monophasé ou triphasé) pour pouvoir changer la fonction d'un appareil. Vous pouvez modifier cette information dans l'etape 1 Installation`,
    measureOfPrefix: `Mesure de`,
    measureAndControlOfPrefix: `Mesure et contrôle de`,
    controlOfPrefix: `Contrôle de`,
    swG3WaterHeaterFunctionSelected: `La configuration du Relay G3 en pilotage de Chauffe eau ne permet pas de mesurer la consommation de ce même chauffe eau. Veuillez vous assurer qu'un élément de comptage est configuré pour mesurer le chauffe eau (GMD G3 ou RS485)`,
    rs485WaterHeaterFunctionSelected: `La configuration du compteur RS485 en mesure de Chauffe eau ne permet pas de piloter le chauffe eau. Veuillez vous assurer qu'un élément de pilotage est configuré pour piloter le chauffe eau (Relay G3 ou TPH)`,
    gmdG3CTWaterHeaterFunctionSelected: `La configuration du GMD G3 en mesure de Chauffe eau ne permet pas de piloter le chauffe eau. Veuillez vous assurer qu'un élément de mesure et configuré pour mesurer le chauffe eau (Relay G3 ou TPH)`,
    signalReceptionGSM: 'Réception du signal GSM ',
    rssi: 'rssi',
    rscp: 'rscp',
    pclRateBetweenMasterAndComOne:
      'Débit CPL entre la Master et la prise COM-1 :',
    tx: `Tx`,
    rx: `Rx`,
    devicesConfigurationModeTitle: 'Configuration des appareils',
    devicesConfigurationModeAuto: `Démarrez l'assistant de configuration`,
    devicesConfigurationModeManual: `Configurez les appareils manuellement`,
    startConfigurationAssistance: `Démarrer l'assistant de configuration`,
    configurationAssistance: `Assistant de configuration`,
    configurationAssistanceCantFinish: `Nous avons détecté que certains appareils CPL (%devicesName%) n'avaient pas encore été appairés à la Master. Afin que l'assistant puisse configurer l'installation veuillez appairer les appareils manquants: Aucune fonction n'a été affectée pour le moment`,
    confirmAffectDevicesFunction: `Les fonctions suivantes vont être affectées aux appareils ci dessus, souhaitez vous l'appliquer?`,
    preconfigurationAppliedWithSuccess: `Préconfiguration appliquée avec succés.`,
    successAffectDevicesFunction: `Toutes les fonctions pré configurables ont été affectés. Si des appareils supplémentaires au coffret ont été ajoutés, ces derniers ne sont pas pré-configurés. Veuillez validez et terminer l'affectation des fonctions à l'étape suivante.`,
    devicesRS485Added: `Les appareils RS485 suivant ont été ajoutés:`,
    address: `Adresse`,
    UCG_CONTAINS_TOO_MANY_GMD: `Le modèle d'UCG installé comporte plusieurs GMD. Par conséquent il n'est pas possible de préconfigurer les fonctions des GMD`,
    UCG_CONTAINS_TOO_MANY_TPH: `Le modèle d'UCG installé comporte plusieurs TPH. Par conséquent il n'est pas possible de préconfigurer les fonctions des TPH`,
    MST_CONFIG_FILE_CONTAINS_MORE_GMD_THAN_UCG: `Des GMD supplémentaires ont été appairés à la Master. Par conséquent il n'est pas possible de préconfigurer les fonctions des GMD`,
    MST_CONFIG_FILE_CONTAINS_MORE_TPH_THAN_UCG: `Des TPH supplémentaires ont été appairés à la Master. Par conséquent il n'est pas possible de préconfigurer les fonctions des TPH`,
    MST_CONFIG_FILE_CONTAINS_LESS_GMD_THAN_UCG: `Il manque un ou plusieurs GMD appairé(s) à la Master. Par conséquent il n'est pas possible de préconfigurer les fonctions de ces appareils`,
    MST_CONFIG_FILE_CONTAINS_LESS_TPH_THAN_UCG: `Il manque un ou plusieurs TPH appairé(s) à la Master. Par conséquent il n'est pas possible de préconfigurer les fonctions de ces appareils`,
    UCG_MODEL_CONTAINS_MANY_MODBUS_DEVICE_WITH_SAME_REFERENCE: `Le modèle d'UCG installé comporte plusieurs appareils RS485 avec la même référence. Par conséquent il n'est pas possible de préconfigurer les fonctions de ces appareils`
  },
  RoomName: {
    workshop: 'Atelier',
    other_area_type: 'Autre',
    library: 'Bibliothèque',
    washroom: 'Buanderie',
    office: 'Bureau',
    cellar: 'Cave',
    storeroom: 'Cellier',
    bedroom: 'Chambre',
    boiler_room: 'Chaufferie',
    corridor: 'Couloir',
    kitchen: 'Cuisine',
    outbuilding: 'Dépendance',
    dressing_room: 'Dressing',
    entry: 'Entree',
    garage: 'Garage',
    alaska_home: 'Habitation',
    garden: 'Jardin',
    swimming_pool: 'Piscine',
    dining_room: 'Salle à manger',
    bathroom: 'Salle de bain',
    shower_room: 'Salle de douche',
    playroom: 'Salle de jeux',
    sports_room: 'Salle de sport',
    living_room: 'Salon',
    porch: 'Véranda',
    water_closet: 'WC'
  },
  DevicesFunctions: {
    asoka_red_plug: 'Appareil rouge',
    aspirator: 'Aspirateur',
    other_device_type: 'Autre',
    bbq_hot_stone: 'BBQ Plancha',
    battery: 'Batterie',
    kettle: 'Bouilloire',
    wine_cellar: 'Cave à vin',
    water_heater: 'Chauffe eau',
    water_heater_switch: 'Interrupteur de chauffe eau',
    composite_device: 'Composite device',
    asoka_electric_counter: 'Compteur de consommation',
    freezer: 'Congélateur',
    game_console: 'Console de jeux',
    electric_car: 'Voiture électrique',
    speaker: 'Enceintes',
    iron: 'Fer à repasser',
    oven: 'Four',
    refrigerator: 'Frigidaire',
    refrigerator_freezer: 'Frigidaire congélateur',
    deep_fryer: 'Friteuse',
    toaster: 'Grille pain',
    stereo_system: 'Hifi',
    home_cinema_system: 'Home cinéma',
    production_counter_ignored: 'Compteur ignoré',
    printer: 'Imprimante',
    lamp: 'Lampe',
    dishwasher: 'Lave Vaisselle',
    washing_machine: 'Lave linge',
    washing_drying_machine: 'Lave linge sèche linge',
    dvd_player: 'Lecteur DVD',
    coffee_machine: 'Machine à café',
    microwave_oven: 'Micro onde',
    computer: 'Ordinateur',
    production_counter: 'Compteur de production',
    pump: 'Pompe',
    heater: 'Radiateur',
    radio: 'Radio',
    razor: 'Rasoir',
    alarm_clock: 'Réveil',
    food_processor: 'Robot de cuisine',
    hair_dryer: 'Sèche cheveux',
    dryer: 'Sèche linge',
    phone: 'Téléphone',
    tv: 'Télévision',
    ventilator: 'Ventilateur',
    video_projector: 'Vidéo projecteur',
    virtual: 'Virtual device',
    tado_ac: 'Climatisation Tado',
    tado_heater: 'Thermostat Tado',
    wiser_hrelay: 'Wiser Relai',
    wiser_thermostat: 'Thermostat Wiser',
    wiser_valve: 'Valve Wiser',
    my_smart_battery: 'My Smart Battery'
  },
  DeviceDetail: {
    mst1: `• Branchez directement sur la prise électrique murale à proximité de votre box Internet.
• Avec le câble Ethernet fourni, connectez la SmartPlug à votre box Internet.
• Branchez la prise de votre box Internet sur la SmartPlug.
`,
    mst2: `Attention ! Toute SmartPlug se branche directement à une prise murale pour limiter les interférences. Si vous devez utiliser une multiprise, celle-ci se branche sur la SmartPlug et non à la prise électrique murale.`,

    mst_g3mono1: `La Smart Master G3 est un compteur 3 en 1 qui mesure la production photovoltaïque, la consommation globale du bâtiment et qui gère les gros appareils électriques (dont chauffe-eau).

      Il comprend donc 3 entrées de comptage (consommation, production, équipement). Ce compteur a plusieurs options de connectivité à internet : par câble Ethernet RJ45, par prise CPL ou via modem cellulaire (en option). Enfin il possède des connectivités externes telles qu’une passerelle RS485 et 2 ports Ethernet.
      `,
    mst_g3mono2: `
    Branchement :

      • Choisir et brancher un disjoncteur P+N 2A courbe C sur l’installation de la maison
      • Alimenter le Neutre (N) depuis le neutre du disjoncteur
      • Alimenter la phase (L1) depuis la phase unique du disjoncteur
      `,
    mst_g3mono3: `
    Branchement transformateur de courant :
    `,
    mst_g3mono4: `
    Les transformateurs de courant se placent autour des câbles de phase de la maison, comme indiqué sur le schéma ci-dessus.

    En cas d’installation triphasée, deux autres transformateurs de courant doivent être mis en place autour des câbles L2 et L3, et connectés respectivement sur les entrées CT2 et CT3 de l’équipement.

    La connexion des transformateurs de courant doit être complète et le connecteur vissé.
    `,
    mst_g3mono5: `Attention ! Le connecteur est détrompé, ne jamais forcer pour l’insérer.`,

    mst_g3tri1: `La Smart Master G3 est un compteur 3 en 1 qui mesure la production photovoltaïque, la consommation globale du bâtiment et qui gère les gros appareils électriques (dont chauffe-eau).

      Il comprend donc 3 entrées de comptage (consommation, production, équipement). Ce compteur a plusieurs options de connectivité à internet : par câble Ethernet RJ45, par prise CPL ou via modem cellulaire (en option). Enfin il possède des connectivités externes telles qu’une passerelle RS485 et 2 ports Ethernet.
`,
    mst_g3tri2: `
    Branchement :

      • Choisir et brancher un disjoncteur 4P 2A courbe C sur l’installation de la maison
      • Alimenter le Neutre (N) depuis le neutre du disjoncteur
      • Alimenter les trois phases (L1, L2, L3) depuis chacune des phases de l’installation
      `,
    mst_g3tri3: `
    Branchement transformateur de courant : `,
    mst_g3tri4: `
    Les transformateurs de courant se placent autour des câbles de phase de la maison, comme indiqué sur le schéma ci-dessus.

    En cas d’installation triphasée, deux autres transformateurs de courant doivent être mis en place autour des câbles L2 et L3, et connectés respectivement sur les entrées CT2 et CT3 de l’équipement.

    La connexion des transformateurs de courant doit être complète et le connecteur vissé.
    `,
    mst_g3tri5: `Attention ! Le connecteur est détrompé, ne jamais forcer pour l’insérer.`,

    eth1: `•  Branchez directement sur la prise électrique murale.
• Pour connecter un appareil à internet, reliez-le à la SmartPlug à l’aide du câble Ethernet fourni.
• Branchez l’alimentation de cet appareil sur le SmartPlug.
`,
    eth2: `Attention ! Toute SmartPlug se branche directement à une prise murale pour limiter les interférences. Si vous devez utiliser une multiprise, celle-ci se branche sur la SmartPlug et non à la prise électrique murale.`,
    sw1: `• Branchez directement sur la prise électrique murale.
• Branchez l’alimentation de l’appareil de votre choix sur la SmartPlug.
`,
    sw2: `Attention ! Toute SmartPlug se branche directement à une prise murale pour limiter les interférences. Si vous devez utiliser une multiprise, celle-ci se branche sur la SmartPlug et non à la prise électrique murale.`,
    sw_g31: `• Branchez directement sur la prise électrique murale.
• Branchez l’alimentation de l’appareil de votre choix sur la SmartPlug.
`,
    sw_g32: `Attention ! Toute SmartPlug se branche directement à une prise murale pour limiter les interférences. Si vous devez utiliser une multiprise, celle-ci se branche sur la SmartPlug et non à la prise électrique murale.`,
    gmdmono1: `Les transformateurs de courant se placent autour des câbles de phase de la maison, comme indiqué sur le schéma ci-dessous. La prise jack à l’autre extrémité doit être branchée sur l’entrée CT1 du compteur mylight150.`,
    gmdmono2: `Attention ! Bien respecter le sens du courant indiqué sur le transformateur de courant par deux flèches présentes sur son sommet, conformément au schéma ci-dessous`,
    gmdtri1: `Les transformateurs de courant se placent autour des câbles L1, L2, L3 de phase de la maison, comme indiqué sur le schéma ci-dessous. Les prises jack à l’autre extrémité doivent être branchées respectivement sur les entrée CT1, CT2 et CT3 du compteur mylight150.`,
    gmdtri2: `Attention ! Bien respecter le sens du courant indiqué sur le transformateur de courant par deux flèches présentes sur son sommet, conformément au schéma ci-dessous`,
    gmd_g3mono1: `Les transformateurs de courant se placent autour des câbles de phase de la maison, comme indiqué sur le schéma ci-dessous. La prise jack à l’autre extrémité doit être branchée sur l’entrée CT1 du compteur mylight150.`,
    gmd_g3mono2: `Attention ! Bien respecter le sens du courant indiqué sur le transformateur de courant par deux flèches présentes sur son sommet, conformément au schéma ci-dessous`,
    gmd_g3tri1: `Les transformateurs de courant se placent autour des câbles L1, L2, L3 de phase de la maison, comme indiqué sur le schéma ci-dessous. Les prises jack à l’autre extrémité doivent être branchées respectivement sur les entrée CT1, CT2 et CT3 du compteur mylight150.`,
    gmd_g3tri2: `Attention ! Bien respecter le sens du courant indiqué sur le transformateur de courant par deux flèches présentes sur son sommet, conformément au schéma ci-dessous`,
    rs485_mc11: `Il permet de mesurer la consommation d’un appareil électrique d’une installation monophasé ou triphasé. Il communique en RS485 avec la Smart Master G3.`,
    rs485_mc12: `
    Branchement :
     • Choisir et brancher un disjoncteur adapté à la charge
     • Alimenter le Neutre (N) depuis le neutre du disjoncteur
     • Alimenter la phase (L1) depuis la phase unique du disjoncteur
     • Connecter la charge sur le compteur`,
    rs485_mc31: `Il permet de mesurer la consommation d’un appareil électrique d’une installation monophasé ou triphasé. Il communique en RS485 avec la Smart Master G3.`,
    rs485_mc32: `
    Branchement :
     • Choisir et brancher un disjoncteur adapté à la charge
     • Alimenter le Neutre (N) depuis le neutre du disjoncteur
     • Alimenter les trois phases (L1, L2, L3) depuis les phases du disjoncteur
     • Connecter la charge sur le compteur`
  },
  Partnerships: {
    partners: 'Comptes Tiers',
    enphaseSystemName: 'Enphase',
    tadoSystemName: 'Tado',
    wiserSystemName: 'Wiser',
    validate: 'Enregistrer',
    connect: 'Connecter',
    cancel: 'Annuler',
    connectedStatus: 'connecté',
    inProgressStatus: 'en cours',
    disconnectedStatus: 'non connecté',
    connectingInProgress: `L'enregistrement de comptes tiers n'est pas encore terminé`
  },
  EnphasePartnership: {
    emailExists: `Voulez vous utiliser la même adresse Email pour l'enregistrement sur Enlighten?`,
    email: `Email du client`,
    emailConfirmation: `Confirmer email`,
    emailWarning: `L'e-mail sera également mis à jour dans la fiche client de mylight150`,
    enphaseSystemId: `ID du Système Enphase`,
    enphaseInstallerId: 'ID Installer Enphase',
    whereIsInstallerID: {
      title: `Où trouver l'ID Installer Enphase ?`,
      step1: `Accédez à votre compte <a class='text-primary' target='_blank' href='https://enlighten.enphaseenergy.com/'>Enlighten</a>.<br/>
        Une fois connecté, allez dans le <b>menu > Compte > Profil de la société</b>.`,
      step2: `Allez <b>en bas de page</b> Profil de la société.<br/>
        Vous trouverez l'ID Installer (<b>XXXXXX</b>) dans la rubrique <b>Liste des systèmes de référence</b>.`
    },
    enphaseInstallerIdDescription: `Attribuez-vous les droits d'accès ENVOY immédiatement, pour cela enregistrez l'ID Installer Enphase`,
    enphaseDetails: 'Détails de la Enphase connexion',
    envoySerialNumber: `Numéro de série de l'Envoy`,
    envoyRegistered: `Le système Enphase est il déjà enregistré?`,
    wrongEnvoyFormat: `Le numéro d'Envoy doit contenir 12 caractères.`,
    updateEmail: `Modifier l'email`,
    wrongSystemIdFormat: `System Id doit contenir entre 5 et 7 caractères.`,
    wrongEmail: `Veuillez verifier l'email`,
    wrongInstallerIdFormat: `L'ID Installer doit contenir au moins 2 caractères numériques.`
  },
  EnphaseErrors: {
    email: 'Cet email est deja utilisé sur Enphase',
    emailExists: `L'utilisateur avec cet email existe déjà!`,
    registration: `Enphase erreur d'inscription, veuillez réessayer`,
    serialNumber: `Le numéro de série n'est pas valide!`,
    activationCode: `Le code d'activation n'est pas valide!`,
    owner:
      'Ce numéro de série Envoy est déjà enregistré, veuillez contacter le support Enphase.',
    systemId: `L'ID système de l'appareil n'est pas valide`,
    emailUpdate: `Erreur lors de la mise à jour d'un email, veuillez réessayer.`,
    unknown: `Une erreur inconnue s'est produite lors de l'enregistrement Envoy, veuillez réessayer.`
  },
  TadoPartnership: {
    login: 'Vous devez vous connecter dans la fenêtre Tado.',
    connectingTado: 'Connexion Tado',
    selectTadoHome: 'Sélectionnez le Tado Home que vous souhaitez connecter',
    tadoHome: 'Tado Home',
    connectTadoHome: 'Connexion Tado Home',
    resumeTadoConnecting: 'Reprendre la connexion Tado',
    disconnectTado: 'Déconnecter',
    detectedDevices: 'Les appareils suivants ont été détectés:',
    connectedDevices: 'Les appareils suivants ont été connectés:',
    reopenTadoLoginWindow: 'Réouvrir la fenêtre de connexion Tado',
    validate: 'Valider',
    devicesAddingError: `Une erreur inconnue s'est produite lors de l'enregistrement Tado, veuillez réessayer.`,
    devicesAddingSuccess: `Les appareils Tado ont été enregistrés avec succès`,
    deviceRemovingError: `Une erreur inconnue š'est produite lors de la suppression de l'appareil Tado`,
    deviceRemovingSuccess: `Les appareils Tado ont été supprimés avec succès`,
    tadoDisconnectingError: `Une erreur inconnue lors de la déconnexion Tado`,
    tadoDisconnectingSuccess: `Tado a été déconnecté avec succès`,
    noTadoDevices: `Sélectionné Tado Home n'a aucun appareil`,
    noDetectedDevices: "Aucun appareil n'a été détecté"
  },
  TadoErrors: {
    getDevices:
      'Erreur lors de la lecture des appareils Tado Home, veuillez réessayer.',
    homeNotFound: 'Tado Home est introuvable'
  },
  Success: {
    updateCustomer: `Le client a été mis à jour avec succès`,
    updateInstallation: `L'installation a été mise à jour avec succès`,
    updateUCG: `L'UCG a été mise à jour avec succès`,
    updateMaster: `La Master a été mise à jour avec succès`,
    updateDevice: `L'appareil a été mis à jour avec succès`,
    updateDevices: `Les appareils ont été mis à jour avec succès`,
    updateFunctions: `Les fonctions des appareils ont été mis à jour avec succès`,
    updateMSB: `My Smart Battery a été mise à jour avec succès`,
    finishInscription: `Inscription des appareils réussie`,
    sendInscriptionSummary: `L'Email de partage de l'etat d'inscription a bien été envoyé`
  },
  SummaryWarnings: {
    missingPLCHub: `Vous avez déclaré que la Master devait être connectée à Internet via un autre prise CPL mylight150, or cela ne semble pas être le cas, aucun appareil remplissant ce rôle n'a été détecté`,
    missingGSM: `Vous avez déclaré que la Master devait être connectée à Internet via un modem cellulaire (routeur GSM), or cela ne semble pas être le cas, aucun appareil remplissant ce rôle n'a été détecté.`
  },
  Errors: {
    saveCustomer: 'Erreur lors de la création de client',
    saveCustomerSrs:
      'Une erreur est survenue pendant la création du client. Faite une nouvelle tentative, sinon réessayer plus tard. Si le problème persiste contacter le support',
    assignError: `L'utilisateur a bien été créé, mais une erreur s'est produite lors de son association à un installateur`,
    unknown: `Erreur inconnue`,
    serverError: 'Une erreur est survenue.',
    dataAccessBlocked: `Le client n'autorise pas l'accès à ses données d'installation et ses données de consommation/production.`,
    masterAlreadyUsed: `Cet appareil est déjà associé à un utilisateur`,
    mstNotConnected: `Impossible de joindre la Master`,
    mstNotConnectedExtended:
      'Une erreur est survenue. Impossible de contacter la Master. Veuillez réessayer.' +
      ' Si le problème persiste vérifiez que la Master est bien raccorder à internet.',
    mstInvalidAC: "Code d'activation invalide",
    mstInvalidMAC: 'Adresse MAC invalide',
    mstConnectedWithGSM:
      'Le mode de connexion sélectionné semble incorrect (un modem est détecté)',
    mstConnectedWithPLC:
      'Le mode de connexion sélectionné semble incorrect (une Prise CPL mylight150 est détectée)',
    noSignalDetected: 'Aucun signal détecté',
    unknownError: 'Master erreur inconnue',
    plcError: `Une erreur est survenue, vérifiez le code d'activation ou l'adresse MAC de l'appareil que vous essayez de connecter et vérifiez si votre appareil Master est correctement branché et alimenté.`,
    finishInscription: `Un erreur est survenu lors de l'inscription des appareils`,
    tooLongStringError: `La saisie est trop longue, 50 caractères maximum sont autorisés`,
    numberFormatError: `Format de nombre invalide`,
    countryIsNotSupported: `Ce pays n'est pas supporté`,
    geocoderError: `Une erreur est survenu lors de la recherche de l'adresse`,
    rackingPowerError: `Puissance de soutirage valeur invalide`,
    sendInscriptionSummary: `L'adresse email contient des caractères invalides`,
    customerNotFound: 'Utilisateur introuvable. Veuillez contacter le support',
    installationUserNotFound: `Impossible de charger les données d'installation, utilisateur introuvable. Veuillez contacter le support`,
    wModbusBoxSerialError: 'Le numéro de série du coffret est invalide',
    wModbusBoxDeviceNotFound:
      "Aucun appareil lié à ce numéro de série n'a été trouvé",
    wModbusBoxDeviceUnavailable:
      'Ce coffret est déjà enregistré. Veuillez vérifier le numéro de série ou contacter le support',
    wModbusPartDeviceUnavailable:
      'Cette pièce détachée est déjà enregistrée. Veuillez vérifier le numéro de série ou contacter le support',
    wModbusPartSerialError:
      'Le numéro de série de la pièce détachée est invalide',
    wModbusPartDeviceMissingDevices:
      'Au moins un appareil RS485 doit être associé',
    deletionMyLightMsb: 'Cette batterie ne peut pas être supprimé',
  },
  ErrorsBack: {
    customerMailAlreadyExist: 'Cet email est déjà utilisé'
  },
  PowerContractType: {
    flat: 'Base',
    custom: 'Heures creuses /Heures pleines',
    predefined: 'Autre'
  },
  PowerContractOption: {
    ejp: 'EJP',
    tempo: 'Tempo',
    other: 'Autre'
  },
  StepError: {
    room: 'Une erreur est survenue lors de l\'étape: "Creation des pièces"',
    device:
      'Une erreur est survenue lors de l\'étape: "Creation des appareils" ',
    deviceVrt:
      "Une erreur est survenue lors de l'étape: \"Creation de l'appareil virtuel'\" ",
    greenPlay:
      'Une erreur est survenue lors de l\'étape: "Actvation du greenplay" ',
    unknown: "Une erreur unconnue est survenue lors de l'inscription "
  },
  StepTodo: {
    summary: `Afin de finaliser l'inscription toutes les étapes doivent etre validées`,
    installation: `Les champs suivie d'un astérisque sont obligatoires`,
    functionsNoCounter: `Au moins un compteur de production ou un compteur de consommation globale doit etre defini`,
    functionsForbidden: `Un ou plusieurs appareils sont configurés pour une installation triphasée, alors que l'installation actuelle est déclarée comme étant monophasée.`,
    modbusProductionCounter:
      'Le compteur physique peut afficher une valeur négative, cela n’impacte pas l’installation'
  },
  ToolsPairing: {
    verifPairing: `Vérification de l'appairage`,
    testPairing: `Test d'appairage`,
    completeSystem: 'Système complet',
    notPermissionToAccessPairingToolkit: `Vous n'avez pas l'autorisation d'accéder à l'outil de test d'apparaige`,
    refreshPower: `Rafraîchir les puissances`
  },
  SidebarGuide: {
    gettingStartedGuideLabel: 'Guide de démarrage',
    hardwareRegistrationTutorialLabel: 'Tutoriel d’inscription du matériel',
    mistakesNotToMakeLabel: 'Les erreurs à ne pas commettre',
    guideToProceduresLabel: 'Guide des démarches',
  }
};
