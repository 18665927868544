export const dashboardSidebarGuideLinks = [
  {
    label: 'SidebarGuide:gettingStartedGuideLabel',
    url: 'https://news.mylight150.com/guide_demarrage_partenaires_mysmartbattery',
  }
];

export const registrationStepperSidebarGuideLinks = [
  {
    label: 'SidebarGuide:hardwareRegistrationTutorialLabel',
    url: 'https://www.loom.com/share/eb9e5ca14ce64ced886e24804e936192?sid=947ef170-9bcc-4820-b76a-59892faf41cc',
  },
  {
    label: 'SidebarGuide:mistakesNotToMakeLabel',
    url: 'https://cdn.prod.website-files.com/65f08fc6f2c52e2310a49dcb/6708dd2c79c85c8b005e1216_ERREURS%20COMMUNES%20INSTALLATION.pdf',
  }
];

export const installationTabSidebarGuideLinks = [
  {
    label: 'SidebarGuide:guideToProceduresLabel',
    url: 'https://mylight150.notion.site/D-marches-administratives-MySmartBattery-sur-MyProPortal-62a49650fd9d4111adee2fc209930c95',
  },
];