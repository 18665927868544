import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import './sidebar-guide.scss';

export interface ISidebarGuideLinks {
  label: string;
  url: string;
}

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  items: ISidebarGuideLinks[];
}

const SidebarGuide = ({
  t,
  items
}: IProps) => {
  const hasItems = items.length > 0;
  return (
    <div className="sidebar-guide">
      <div className="sidebar-guide__icon-container">
        <span className="sidebar-guide__icon">
          &#127891;
        </span>
      </div>

      <ul className="sidebar-guide__items">
        { hasItems && items.map((item, index) => (
            <li className="sidebar-guide__item" key={ `sidebar-guide-item-${++index}` }>
              <a href={ item.url } className="sidebar-guide__item-link">
                { t(item.label) }
              </a>
            </li>
          )) }
      </ul>
    </div>
  );
};

export default withRouter(
  translate(['SidebarGuide, Common'])(SidebarGuide)
);