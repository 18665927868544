import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import { IconSpinner } from '../../../../../ui/Icons/IconSpinner';
import { IconSuccess } from '../../../../../ui/Icons/IconSuccess';
import { IconWarning } from '../../../../../ui/Icons/IconWarning';
import { IconPencil } from '../../../../../ui/Icons/IconPencil';
import './MsbInput.scss';

type InputState = 'success' | 'error';
interface IInputHelperText {
  error?: string;
  info?: string;
}

interface IProps {
  id: string;
  name: string;
  addonRight?: ReactNode;
  disabled?: boolean;
  inputState?: InputState;
  helperText?: IInputHelperText;
  loading?: boolean;
  placeholder?: string;
  ref?: (a: any) => any;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  onBlur?: (value: number) => void;
  onChange?: (value: number) => void;
}

export const MsbInputNumber = (formProps: IProps) => {
  const {
    id,
    name,
    addonRight,
    disabled,
    inputState,
    helperText,
    loading,
    placeholder,
    ref,
    value,
    min,
    max,
    step,
    onBlur,
    onChange
  } = formProps;

  const [isUpdate, setIsUpdate] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const hasInfoHelperText: boolean = 
    !!(helperText && helperText.info && helperText.info !== '');

  const hasError = () => {
    return inputState === 'error' && !disabled;
  };

  const hasSuccess = () => {
    return inputState === 'success' && !disabled;
  };

  const hasAddonRight = () => {
    return !!addonRight;
  };

  const hasDisabled = () => {
    return !!disabled;
  };

  const getIcon = () => {
    if (loading) {
      return <IconSpinner size={16} />;
    }

    if (hasError()) {
      return <IconWarning />;
    }

    if (value !== undefined && !isNaN(value) && !isUpdate) {
      return <IconSuccess />;
    }
    return null;
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    if (isFocus) {
      setIsUpdate(true);
    }
    if (onChange) {
      onChange(event.target.value as number);
    }
  };

  const handleBlur = (event: any) => {
    event.preventDefault();
    setIsFocus(false);
    setIsUpdate(false);
    if (onBlur) {
      onBlur(event.target.value as number);
    }
  };

  const handleFocus = (event: any) => {
    setIsFocus(true);
  };

  return (
    <div
      className={classnames({
        'msb-activation-form-group--with-error': hasError(),
        'msb-activation-form-group--with-success': hasSuccess(),
        'msb-activation-form-group--with-addon-right': hasAddonRight(),
        'msb-activation-form-group--disabled': hasDisabled()
      })}
    >
      <div className="msb-activation-form-group__input-and-icon-container">
        <div className="msb-activation-form-group__input-and-addon-right-container">
          <div className="msb-activation-form-group__input-container">
            <input
              id={id}
              type="number"
              name={name}
              value={value || ''}
              min={min}
              max={max}
              step={step}
              ref={ref}
              className={classnames('msb-activation-form-group__input', {
                'msb-activation-form-group__input_error': hasError(),
                'msb-activation-form-group__input_success': hasSuccess()
              })}
              onChange={event => handleChange(event)}
              onBlur={event => handleBlur(event)}
              onFocus={event => handleFocus(event)}
              disabled={disabled}
              placeholder={placeholder}
            />
            {!hasDisabled() && (
              <IconPencil className="msb-activation-form-group__edit-icon" />
            )}
          </div>
          {addonRight && (
            <div className="msb-activation-form-group__addon-right">
              {addonRight}
            </div>
          )}
        </div>
        <div className="msb-activation-form-group__icon">{getIcon()}</div>
      </div>
      {helperText && (
        <div className="msb-activation-form-group__helper-text">
          {!hasError() && 
            hasInfoHelperText && (
              <span className="msb-activation-form-group__helper-text--info">
                { helperText.info }
              </span>
            )
          }

          {hasError() &&
            helperText.error && (
              <span className="msb-activation-form-group__helper-text--error">
                {helperText.error}
              </span>
            )}
        </div>
      )}
    </div>
  );
};
