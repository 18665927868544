import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FieldList, Title } from '../../ui/DataDisplay/FieldList/FieldList';
import { translate, TranslationFunction } from 'react-i18next';
import tools from '../../utils/tools';

interface IProps {
  t: TranslationFunction;
}
const TechnicalDocumentation = ({ t }: IProps) => {
  const openNewTabWithDocumentationPage = (pageName: string) => {
    let externalPageUrl = '';

    switch (pageName) {
      case 'guide':
        externalPageUrl = 'https://news.mylight150.com/guide_demarrage_partenaires_mysmartbattery';
        break;

      case 'technical':
        externalPageUrl = 'https://www.mylight150.com/documentation-technique';
        break;

      case 'faq':
        externalPageUrl = 'https://faq.mylight150.com/fr/#gestiondelenergie';
        break;
    
      default:
        externalPageUrl = 'https://faq.mylight150.com/fr/#gestiondelenergie';
        break;
    }

    tools.openNewTabfForURI(externalPageUrl);
  };

  return (
    <Col className="technical-support mobile-helper" xs={12}>
      <FieldList>
        <Title text={t('documentationTitle')} />
        <Row className="technical-support__buttons">
          <Col sm={12} lg={6} xl={3}>
            <Button block onClick={ () => openNewTabWithDocumentationPage('guide') }>
              {t('gettingStartedGuide')}
            </Button>
          </Col>

          <Col sm={12} lg={6} xl={3}>
            <Button block onClick={ () => openNewTabWithDocumentationPage('technical') }>
              {t('technicalDocumentation')}
            </Button>
          </Col>

          <Col sm={12} lg={6} xl={3}>
            <Button block onClick={ () => openNewTabWithDocumentationPage('faq') }>
              {t('faq')}
            </Button>
          </Col>
        </Row>
      </FieldList>
    </Col>
  );
};

export default translate('Support')(TechnicalDocumentation);
